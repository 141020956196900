@media only screen and (max-width: 600px) {
  .alice-carousel__stage-item.__target {
    filter: unset;
  }
}

:root {
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
  position: relative;
}

.alice-carousel__slide-info-item.__separator {
  background-color: aliceblue;
}

.alice-carousel__dots-item.__active {
  background-color: #179446 !important;
}

.alice-carousel__dots-item {
  background-color: #767676 !important;
}

.box3 {
  width: auto;
  height: auto;
  margin: 40px auto;
  border-radius: 15px;
  background: #ffffff;
  color: #3a3a3a;
  padding: 20px;
  inline-size: 170px !important;
  font-size: 14px;
  font-family: "Poppins";
  text-align: center;
  font-weight: 400;
  transform-origin: 0 0;
  transition: transform 0.25s, visibility 0.25s ease-in;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* speech bubble 13 */

.sb13:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid #ffffff;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffffff;
  border-bottom: 15px solid transparent;
  right: -16px;
  box-shadow: 0 4px -1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top: 0px;
}

.sb14:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid #fff;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  left: -16px;
  box-shadow: 0 4px -1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top: 0px;
}

/*Logo Animation */
.slider_logo {
  max-width: 1650px;
  margin: 0 auto;
  overflow: hidden;
}

.slider_logo2 {
  display: flex;
  align-items: center;
  padding: 0;
  animation: cambio 15s linear infinite;
  margin-top: 40px;
}

.slider_logo3 {
  padding: 0 10px;
}

.slider_logo img {
  max-width: fit-content !important;
  height: auto !important;
  margin-right: 20px;
  width: 160px;
}

@keyframes cambio {
  100% {
    margin-left: -2560px;
  }
}

/* .fabimg{
  width: 100%;
  height: 50px;
} */
/*Logo Animation */

/*wegrow Animation */
.slider_wegrow {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.slider_wegrow ul {
  display: flex;
  align-items: center;
  padding: 0;
  animation: cambio2 20s infinite linear;
  margin-top: 40px;
}

.slider_wegrow li {
  padding: 0 10px;
  list-style: none;
}

@keyframes cambio2 {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

/*wegrow Animation */
.MuiSlider-root {
  color: #179446 !important;
}

.MuiSlider-rail {
  background-color: #3a3a3a !important;
  height: 1px !important;
  opacity: 1 !important;
}

.MuiSlider-track {
  background-color: transparent !important;
  border: 0px !important;
}

.container1 {
  overflow: hidden;
  margin-top: 10px;
}

.slider1 {
  animation: slidein 20s linear infinite;
  white-space: nowrap;
  margin-top: 100px;
}

.slider2 {
  animation: slideout 20s linear infinite;
  white-space: nowrap;
  margin-top: 100px;
}

.logos {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0;
}

.fab {
  /* height: 60px;
  width: calc(100% / 7); */
  width: 13%;
  aspect-ratio: 6/2;
  object-fit: contain;
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

.fab4 {
  /* height: 60px;
  width: calc(70% / 4); */
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.fab2 {
  /* width: calc(100% / 7);
  height: 60px; */
  width: 13%;
  align-items: center;
  aspect-ratio: 8/2;
  object-fit: contain;
  animation: fade-out 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

@keyframes slidein {
  from {
    transform: translate3d(-98.5%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-98.5%, 0, 0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
  border: px solid #51ac46;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
} */

.tooltiptext {
  width: 390px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  margin-top: 260px;
  border: 1px solid #51ac46;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
}

.floatingstraight {
  animation-name: floatingstraight;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floatingstraight {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0px, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  transform: rotate(322deg);
}
@keyframes floating {
  0% {
    transform: translate(0, 0px) rotate(322deg);
  }

  50% {
    transform: translate(10px, 15px) rotate(322deg);
  }

  100% {
    transform: translate(0, -0px) rotate(322deg);
  }
}

.floatingmob {
  animation-name: floatingmob;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  transform: rotate(322deg);
}

@keyframes floatingmob {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0px, 10px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floating-right {
  animation-name: floating-right;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  transform: rotate(322deg);
}

@keyframes floating-right {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0px, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

/* text animation */
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #285430 0%,
    #c9e897 29%,
    #4c8a35 67%,
    #285430 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
  font-size: 100px;
  font-family: poppins;
  font-weight: 700;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.video-player {
  width: 100%;
  height: 100%;
}

.video-player[data-isOpen="true"] {
  width: 100%;
  height: 100%;
}

.dock {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.icons {
  display: flex;
  padding: 5px;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100px;
  align-items: flex-end;
}

.icon {
  padding: 5px;
  height: auto;
}

/* card image float*/
.floatingimage:hover {
  animation-name: floatingimg;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingimg {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

/* fixedBtn */
.fixedBtn:hover {
  animation-name: fixedbtn;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

@keyframes fixedbtn {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, -15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.backBtn:hover {
  animation-name: backbtn;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

@keyframes backbtn {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(-15px, 0px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.cardbody:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border: 5px;
  border-style: solid;
  border-color: #179446;
  border-top: 0px;
  border-right: 0px;
  transition: 0.2s ease-in;
}

.main {
  height: auto;
  color: white;
  overflow: hidden;
  width: 100%;
  background: linear-gradient(
      90deg,
      rgba(38, 45, 56, 0.25) 0%,
      rgba(38, 45, 56, 0.430589) 15.86%,
      rgba(38, 45, 56, 0.75) 25.99%,
      rgba(38, 45, 56, 0.85) 40.85%,
      rgba(38, 46, 56, 0.95) 55.61%,
      #263238 100%
    ),
    url("images/contact1.jpg") no-repeat center;
  background-size: cover;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid white !important;
}

.MuiInput-root {
  color: white !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  font-family: poppins !important;
}

.MuiInput-root:before {
  border-bottom: 2px solid white !important;
}

.MuiInputLabel-root {
  color: white !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  font-family: poppins !important;
}

.MuiInputLabel-root.Mui-focused {
  color: white !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  font-family: poppins !important;
}

.MuiFormLabel-root {
  color: white !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  font-family: poppins !important;
}

.MuiInput-root:before {
  border-bottom: 2px solid white !important;
  overflow: hidden !important;
}

.MuiInput-root:after {
  border-bottom: 2px solid white !important;
  overflow: hidden !important;
}

.MuiSelect-select {
  background: transparent !important;
}

.MuiSelect-icon {
  color: white !important;
}

.MuiSelect-icon {
  color: white !important;
}

.MuiChip-root {
  background-color: white !important;
  margin: 10px !important;
}

.MuiCheckbox-root.Mui-checked {
  color: #179446 !important;
}

.mainContainer .box {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

.mainContainer:hover .box {
  position: absolute;
  width: 200px;
  background: #fdfdfd;
  padding: 10px;
  right: 2%;
  z-index: 1;
  border-radius: 10px;
  top: 50%;
  visibility: visible;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.marquee-container {
  position: unset !important;
}

 .MuiSelect-icon{
  color:#159346 !important
}

.MuiSelect-outlined:focus	{
  border:none
}