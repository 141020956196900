.scam-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .seo-header-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            color: #179446;
            font-size: 30px !important;
            font-family: "Poppins !important";
            font-weight: 400px !important;
            user-select: none;
            text-align: center;
        }

        h1 {
            color: #3a3a3a;
            font-size: 28px !important;
            font-family: "Poppins !important";
            font-weight: 400px !important;
            user-select: none;
            text-align: center;

            span {
                color: #179446;
                font-size: 30px !important;
                font-family: "Poppins !important";
                font-weight: 600px !important;
                user-select: none;
                text-align: center;
            }
        }
    }

    .job-offer-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
            color: #179446;
            font-size: 30px !important;
            font-family: "Poppins !important";
            font-weight: 600px !important;
            user-select: none;
            text-align: center;
        }

        p {
            color: #3a3a3a;
            font-size: 20px !important;
            font-family: "Poppins !important";
            font-weight: 400px !important;
            user-select: none;
            text-align: justify;

            span {
                color: #179446;
                font-size: 20px !important;
                font-family: "Poppins !important";
                font-weight: 600 !important;
                user-select: none;
                text-align: center;
            }
        }
    }

    .verification-measures {
        margin: 50px auto;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        border: 1px solid #179446;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        h2 {
            color: #3a3a3a;
            text-align: center;
            margin-bottom: 20px;
            font-family: "Poppins";
        }

        ul {
            list-style-type: disc;
            padding-left: 20px;
            font-family: "Poppins";
        }

        li {
            span {
                color: #179446;
                font-size: 20px !important;
                font-family: "Poppins !important";
                font-weight: 600 !important;
                user-select: none;
                text-align: center;
            }

            margin-bottom: 10px;
        }
    }

    .pledge-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #179446;
        margin-top: 10px;
        border-radius: 10px;
        .pledge-content {
            background-color: #fff;
            /* White background */
            padding: 30px;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            /* Shadow effect */
            h2 {
                color: #3a3a3a;
                font-family: "Poppins";
                font-weight: 600;
                /* Dark gray text color */
            }

            p {
                margin: 10px 0;
                color: #3A3A3A;
                span{
                    color: #179446;
                }
                font-family: "Poppins";
                /* Gray text color */
            }

            .signature {
                font-weight: bold;
            }
        }

    }
}