@import url("https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* .carousel-work .control-dots {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 70px !important;
}

@media only screen and (max-width: 600px) {
  .carousel-work .control-dots {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .carousel-work .control-dots {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .carousel-work .control-dots {
    margin: 10px auto !important;
  }
} */

.carousel-work .control-dots .dot {
  margin: 11px 8px !important;
  background: #d9d9d9 !important;
  width: 13px !important;
  height: 13px !important;
}

.carousel-work .control-dots .dot.selected,
.carousel-work .control-dots .dot:hover {
  background: #159346 !important;
}


